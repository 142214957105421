import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { TextSection } from "../../../Shared/Layout/components/TextSection/TextSection";

export interface MoreAboutMeSectionProps {
  className?: string;
}

const MoreAboutMeSectionInner = (props: MoreAboutMeSectionProps) => {
  const { t } = useTranslation("translations");
  return (
    <TextSection
      className={props.className}
      title={t("contactPage.header.title")}
      description={t("contactPage.header.text")}
    />
  );
};

export const MoreAboutMeSection = styled(MoreAboutMeSectionInner)``;
