import React from "react";
import styled from "styled-components";
import { FormSection } from "../Shared/FormSection/FormSection";
import { TopHeader } from "../Shared/Layout/components/TopHeader/TopHeader";
import { ProcessOfCooperationSection } from "../WhyWithMe/ProcessOfCooperationSection/ProcessOfCooperationSection";
import { ContactButtons } from "./components/ContactButtons/ContactButtons";
import { MoreAboutMeSection } from "./components/MoreAboutMeSection/MoreAboutMeSection";

export interface MainContactSectionProps {
  className?: string;
}

const MainContactSectionInner = (props: MainContactSectionProps) => {
  return (
    <div className={props.className}>
      <TopHeader title="Kontakt" />
      <MoreAboutMeSection />
      <ContactButtons />
      <ProcessOfCooperationSection />
      <FormSection />
    </div>
  );
};

export const MainContactSection = styled(MainContactSectionInner)``;
