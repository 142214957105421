import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Shared/Layout/Layout";
import { MainContactSection } from "../components/Contact/MainContactSection";

const ContactPage = () => (
  <Layout>
    <MainContactSection />
  </Layout>
);

export default ContactPage;
