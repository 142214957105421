import { Container } from "@material-ui/core";
import { Link } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ShortText } from "../../../Home/LetsStartSection/components/RightSide/WhyThisJobBox";
import { Button } from "../../../Shared/Button/Primary/Button";
import { Section } from "../../../Shared/Layout/components/Section/Section";
import { muiTheme } from "../../../Shared/Theme/Theme";

export interface ContactButtonsProps {
  className?: string;
}

export interface ContactButton {
  text: string;
  to: string;
  internal: boolean;
}

const ButtonsWrapper = styled(Container)`
  display: flex !important;
  justify-content: space-evenly;

  ${Button} {
    min-width: 200px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  ${muiTheme.breakpoints.down("sm")} {
    flex-flow: column;
  }
`;

const ContactButtonsInner = (props: ContactButtonsProps) => {
  const { t } = useTranslation("translations");
  const buttons: ContactButton[] = t("contactPage.linkSection.buttons", {
    returnObjects: true
  });

  return (
    <Section className={props.className}>
      <ButtonsWrapper>
        {buttons.map(button => {
          if (button.internal) {
            return (
              <Link to={button.to}>
                <Button>{button.text}</Button>
              </Link>
            );
          } else {
            return (
              <a href={button.to} target="_blank" rel="noopener noreferrer">
                <Button>{button.text}</Button>
              </a>
            );
          }
        })}
      </ButtonsWrapper>
    </Section>
  );
};

export const ContactButtons = styled(ContactButtonsInner)`
  margin-top: 30px;
`;
